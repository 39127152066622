import React from 'react'

import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'

const VideoList = ({ resources }) => (
  <Accordion defaultActiveKey={0}>
    {resources.map(({ node }, i) => (
      <VideoPane key={i} eventKey={i} node={node} />
    ))}
  </Accordion>
)

class VideoPane extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: !!props.show,
    }
    this.toggleShow = this.toggleShow.bind(this)
  }
  toggleShow() {
    this.setState({
      show: !this.state.show,
    })
    if (this.props.closeRest) {
      this.props.closeRest()
    }
  }
  render() {
    const { node } = this.props
    return (
      <Card>
        <Card.Header onClick={this.toggleShow} style={{ cursor: 'pointer' }}>
          <span className="text-primary">{node.title}</span>
        </Card.Header>
        {this.state.show && (
          <Card.Body>
            {node.file ? (
              <video
                controls
                src={node.file.file.url}
                style={{ width: '100%' }}
              />
            ) : (
              node.description && (
                <div
                  className="m-2"
                  dangerouslySetInnerHTML={{
                    __html: node.description.childMarkdownRemark.html,
                  }}
                />
              )
            )}
          </Card.Body>
        )}
      </Card>
    )
  }
}

export default VideoList
