import React from 'react'
import { Link } from 'gatsby'

export default class VideosNav extends React.Component {
  render() {
    const NL = ({ to, children }) => (
      <li className="nav-item">
        <Link
          to={to}
          className={`outline-primary nav-link ${
            to == this.props.path ? 'active' : ''
          }`}
        >
          {children}
        </Link>
      </li>
    )
    return (
      <nav className="d-flex flex-md-row-reverse flex-lg-column justify-content-around align-items-center">
        <ul className="nav nav-tabs justify-content-center my-2">
          <NL to="/resources/videos/intro/">Intro</NL>
          <NL to="/resources/videos/prek/">PreK Videos</NL>
          <NL to="/resources/videos/level1/">Level 1 Videos</NL>
          <NL to="/resources/videos/level2/">Level 2 Videos</NL>
          <NL to="/resources/videos/level5/">Level 5 Videos</NL>
          <NL to="/resources/videos/extra/">Extra Videos</NL>
        </ul>
      </nav>
    )
  }
}
