import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import VideosNav from '../../../components/videos-nav'
import LookingForMore from '../../../components/looking-for-more'
import VideoList from '../../../components/video-list'
import ResourcesNav from '../../../components/resources-nav'
import Layout from '../../../components/layout'

export default class Videos extends React.Component {
  render() {
    const level1 = get(this, 'props.data.level1.edges')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Level 1 Videos for teaching Math in PreK, Kindergarten, Elementary. Free teaching resources from ${siteTitle}`
    return (
      <Layout pageTitle="Level 1 Videos" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <VideosNav path={this.props.path} />

        <h1>Level 1 Videos</h1>
        <VideoList resources={level1} />
        <hr />
        <LookingForMore />
      </Layout>
    )
  }
}

export const Query = graphql`
  query Level1Query {
    site {
      siteMetadata {
        title
      }
    }
    level1: allContentfulResource(
      filter: { collections: { eq: "level1-videos" } }
      sort: { fields: slug }
    ) {
      edges {
        node {
          slug
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          file {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
