import React from 'react'
import { Link } from 'gatsby'

export default class ResourcesNav extends React.Component {
  render() {
    const NL = ({ to, children }) => (
      <li className="nav-item">
        <Link
          to={to}
          className={`outline-primary nav-link ${
            this.props.path.includes(to) ? 'active' : ''
          }`}
        >
          {children}
        </Link>
      </li>
    )
    return (
      <nav className="d-flex flex-md-row-reverse flex-lg-column justify-content-around align-items-center">
        <ul className="nav nav-tabs justify-content-center my-2">
          <NL to="/resources/levels/">
            <i className="fa fa-layer-group mr-2"></i>
            Levels
          </NL>
          <NL to="/resources/videos/">
            <i className="fa fa-photo-video mr-2"></i>
            Videos
          </NL>
          <NL to="/resources/pdfs/">
            <i className="fa fa-file-pdf mr-2"></i>
            PDFs
          </NL>
          <NL to="/resources/games/">
            <i className="fa fa-gamepad mr-2"></i>
            Games
          </NL>
        </ul>
      </nav>
    )
  }
}
